import Layout from "../components/layout"
import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle} meta={data.site.siteMetadata}>
        <Seo title="404: Not Found" />
        <h1>Sorry The Page You Requested Doesn't Exist</h1>
        <p>
          Please try the Search box in the top right corner.
        </p>
        <p>
          Or if you were looking for an agility course you can set this one up and give it a whirl!
        </p>
        <div className="img-thumbnail text-center">
          <img alt="404" className="img-fluid"
            src="https://data.agilitynerd.com/images/courses/404.gif" />
          <br /><a href="/blog/agility/starting/ReadingCourseMaps/"><small>Obstacle Legend</small></a>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          facebook
        }
      }
    }
  }
`
